<app-layout>
  <div class="customer_Right">
    <div *ngIf="isLoading" style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
      ">
      <mat-spinner color="primary"></mat-spinner>
    </div>
    <div class="row">
      <!-- -------------------Asset filter section---------------- -->
      <div class="col-md-2 leftSecPanel1" *ngIf="assetFilterPanelFlag">
        <div class="leftSec1">
          <h3 class="text-center" *ngIf="asset_count > 1">
            {{ asset_count }} Assets
          </h3>
          <h3 class="text-center" *ngIf="asset_count <= 1">
            {{ asset_count }} Asset
          </h3>
          <div class="fltrsDv">
            <h5>Filter</h5>
            <form [formGroup]="assetFilterForm">
              <div class="ckRow">
                <label class="ckLbl">Action Items Only</label>
                <input type="checkbox" formControlName="action_only" name="c1" class="ckBx" />
              </div>
              <!--<div class="ckRow">
                <label class="ckLbl">Pinned Only</label>
                <input type="checkbox" name="c2" class="ckBx" />
              </div>-->
              <div class="SlctRow">
                <select class="slctDd" formControlName="installType">
                  <option value="">Install Type</option>
                  <option *ngFor="let item of assetFilterList.install_type | keyvalue">
                    {{ item.value }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="survey_name" (change)="filterList('service')">
                  <option value="">Survey Name</option>
                  <option *ngFor="let item of serviceNameList" [value]="item.service_id">
                    {{ item.service_name }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="imageName">
                  <option value="">Image Name</option>
                  <option *ngFor="let item of imageList" [value]="item.image_id">
                    {{ item.image_name }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="questions" (change)="filterList('question')">
                  <option value="">Question</option>
                  <option *ngFor="let item of questionList" [value]="item.question_id">
                    {{ item.question }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="answer">
                  <option value="">Answer</option>
                  <option *ngFor="let item of answerList" [value]="item.answer_id">
                    {{ item.answer }}
                  </option>
                </select>
              </div>
              <div class="SlctRow hayhack">
                <input type="text" placeholder="Date Range" class="slctDd" bsDaterangepicker #dp="bsDaterangepicker"
                  placement="right" formControlName="bsRangeValue" [maxDate]="today" readonly />
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="states" (change)="filterList('state')">
                  <option value="">State</option>
                  <option *ngFor="let item of stateList" [value]="item.state_id">
                    {{ item.state_id }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="city">
                  <option value="">City</option>
                  <option *ngFor="let item of cityList" [value]="item.city_id">
                    {{ item.city_id }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="manufacturer" (change)="filterList('manufacturer')">
                  <option value="">Manufacturer</option>
                  <option *ngFor="let item of manufacturerList" [value]="item.manufacturer_id">
                    {{ item.manufacturer_name }}
                  </option>
                </select>
              </div>
              <div class="SlctRow">
                <select class="slctDd" formControlName="model">
                  <option value="">Model</option>
                  <option *ngFor="let item of modelList" [value]="item.model_id">
                    {{ item.model_name }}
                  </option>
                </select>
              </div>
              <div class="frmBtnRow">
                <input type="reset" name="" value="Clear" class="clrFrm" (click)="clearFilter()" />
                <input type="button" name="" value="Apply" class="aplyFrm" (click)="clickToFilter()" />
              </div>
              <div class="frmBtnRow">
                <input type="button" name="" value="Export To Excel" class="aplyFrm" (click)="exportToExcel()" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- --------------------------------------------------------------- -->
      <!-- END-- Asset filter section--END -->
      <!-- --------------------------------------------------------------- -->
      <div [ngClass]="
          selectedIndex === 0
            ? 'col-md-10 rightSecPanel1'
            : 'col-md-12 rightSecPanel1'
        ">
        <div class="rightSec1">
          <!-- tab start -->
          <div class="tabPanelSec">
            <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabClick($event)">
              <mat-tab label="Asset List" [disabled]="assetListTab">
                <div class="tabContSec">
                  <div class="astLst">
                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" border="0" width="100%"
                      cellspacing="0" cellpadding="0">

                      <ng-container matColumnDef="InstitutionAssignedID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-bold">Asset ID</th>
                        <td class="td-click hand-icon" mat-cell *matCellDef="let element" (click)="assetDetails(element)">
                          <tr>
                            <td  class="assetlint_asset_img">
                              <img *ngIf="
                                element.atm_main_image;
                                    else defaultImage
                                  " src="{{ element.atm_main_image }}" alt="" />
                              <ng-template #defaultImage>
                                <img src="./assets/images/img1.jpg" alt="" />
                              </ng-template>
                            </td>
                            <td class="color-black">
                              <h4>{{ element.InstitutionAssignedID }}</h4>
                              <h6>{{ element.ATMDescription }}</h6>
                              <p>
                                {{ element.atm_location_desc }}
                              </p>
                            </td>
                          </tr>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="action_count">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-bold">Action Items</th>
                        <td class="td-click hand-icon" mat-cell *matCellDef="let element">
                          <span [ngClass]="
                          element.action_count == 0
                            ? 'bgClrSpn1'
                            : 'bgClrSpn'
                        ">{{ element.action_count }}</span>
                        </td>
                      </ng-container>


                      <ng-container matColumnDef="ServiceName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-bold">Recent Evaluation</th>
                        <td class="td-click hand-icon color-black" mat-cell *matCellDef="let element">
                          {{ element.ServiceName }} {{element.FieldComplete && element.FieldComplete != '0000 00 00'?(element.FieldComplete | date:'MM/dd/yyyy'):''}}
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                    </table>

                    <div *ngIf="!totalrecordFound" class="noDataFound">No data found</div>
                      <mat-paginator *ngIf="totalrecordFound" [hidePageSize]="true" [pageSizeOptions]="[10, 20, 50, 100]"
                      [length]="totalrecordFound" [pageSize]="10" (page)="onPaginateChange($event)"></mat-paginator>

                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Asset Profile" [disabled]="assetProfileTab">
                <div class="tabContSec" *ngIf="selectedIndex !== 0">
                  <div class="astProf">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="galDv">
                          <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                              <ng-container *ngIf="
                                  assetProfileList.image_list.length > 0;
                                  then showProfileSlider;
                                  else dontShowProfileSlider
                                ">
                              </ng-container>
                              <ng-template #showProfileSlider>
                                <div class="caro_container">
                                  <div class="carousel-container position-relative row">
                                    <div id="myCarousel" class="carousel slide" data-ride="carousel">
                                      <div class="carousel-inner">
                                        <div [ngClass]="
                                            i == 0
                                              ? 'carousel-item active'
                                              : 'carousel-item'
                                          " *ngFor="
                                            let item of assetProfileList.image_list;
                                            let i = index
                                          " [attr.data-slide-number]="i">
                                          <img class="d-block w-100" src="{{item.public_url}}" alt="{{ i }} slide" />
                                          <!-- <p>
                                              {{ item.image_name }}
                                            </p> -->
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Carousel Navigation -->
                                    <div id="carousel-thumbs" class="carousel slide" data-ride="carousel"
                                      data-interval="0">
                                      <div class="carousel-inner">
                                        <div [ngClass]="
                                            j == 0
                                              ? 'carousel-item active'
                                              : 'carousel-item'
                                          " *ngFor="
                                            let itemThumbList of assetProfileList.image_thumb_list;
                                            let j = index
                                          ">
                                          <div class="row mx-0">
                                            <div *ngFor="
                                                let thumb of itemThumbList;
                                                let i = index
                                              " id="carousel-selector-{{
                                                thumb.id
                                              }}" class="thumb col-4 col-sm-2 px-1 py-2 remove-selection-thumb-slider"
                                              data-target="#myCarousel" [attr.data-slide-to]="thumb.id" (click)="
                                                showSliderThumb(thumb.id)
                                              ">
                                              <img src="{{thumb.item.public_url}}" alt="{{ thumb.id }} slide-thumb"
                                                class="img-fluid" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <a class="carousel-control-prev" href="#carousel-thumbs" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                      </a>
                                      <a class="carousel-control-next" href="#carousel-thumbs" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                      </a>
                                    </div>
                                  </div>
                                  <!-- /row -->
                                </div>
                              </ng-template>
                              <ng-template #dontShowProfileSlider>
                                <div>
                                  <p>No Image Added</p>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div class="profDtls">
                          <h2>
                            {{
                            assetProfileList.atm_information[0]
                            .institution_assigned_id
                            }}
                          </h2>
                          <h6>
                            {{ assetProfileList.atm_information[0].atm_manufacturer }} {{
                            assetProfileList.atm_information[0].atm_model }}
                          </h6>
                          <h6>
                            <span>{{ assetProfileList.atm_information[0].exterior ?
                              assetProfileList.atm_information[0].exterior :
                              assetProfileList.atm_information[0].interior}}</span>
                            <span
                              *ngIf="assetProfileList.atm_information[0].exterior || assetProfileList.atm_information[0].interior">
                              - </span>
                            <span>{{ assetProfileList.atm_information[0].walkup ?
                              assetProfileList.atm_information[0].walkup :
                              assetProfileList.atm_information[0].driveup}}</span>
                            <span
                              *ngIf="assetProfileList.atm_information[0].walkup || assetProfileList.atm_information[0].driveup">
                              - </span>
                            <span>{{ assetProfileList.atm_information[0].kiosk ?
                              assetProfileList.atm_information[0].kiosk :
                              assetProfileList.atm_information[0].freestanding ?
                              assetProfileList.atm_information[0].freestanding :
                              assetProfileList.atm_information[0].through_the_wall}}</span>
                          </h6>
                          <h4>
                            {{
                            assetProfileList.atm_information[0]
                            .atm_description
                            }}
                          </h4>

                          <p>
                            {{
                            assetProfileList.atm_information[0]
                            .location_description
                            }}
                          </p>

                          <p *ngIf="
                              assetProfileList.atm_information[0].comments != ''
                            ">
                            Access Notes:
                            {{ assetProfileList.atm_information[0].comments }}
                          </p>

                          <p *ngIf="assetProfileList.last_service.length > 0">
                            Last Service Date:{{
                            assetProfileList.last_service[0].field_complete
                            }}<br />
                            Last Service:
                            {{ assetProfileList.last_service[0].service_name }}
                            <br />
                            Technician:
                            {{ assetProfileList.last_service[0].host_name }}
                          </p>

                          <p>
                            <img *ngIf="
                                assetProfileList.action_items.action_count == 0
                              " src="./assets/images/circleTickG.png" alt="" />

                            <span>No of Action Items :
                              <span [ngClass]="
                                  assetProfileList.action_items.action_count ==
                                  0
                                    ? 'bgClrSpn1'
                                    : 'bgClrSpn'
                                ">{{
                                assetProfileList.action_items.action_count
                                }}</span></span>
                            <br />
                          </p>

                          <!--<ng-container *ngIf="assetProfileList.corelated_atms.length > 0 ; showCorelatedAtms;">
                          </ng-container>-->

                          <div class="profBtm" *ngIf="assetProfileList.corelated_atms.length > 0">
                            <h3>Co-Located Assets:</h3>
                            <div class="row">
                              <div *ngFor="
                                  let item of assetProfileList.corelated_atms
                                " class="col-4">
                                <a (click)="navToProd(item)" style="cursor: pointer">
                                  <div class="profBtmcol d-flex align-items-center">
                                    <!--<img
                                          src="./assets/images/img1.jpg"
                                          alt=""
                                        />-->
                                    <img src="{{ item.s3path }}" alt="" />
                                    <span class="nmbr">{{ item.institution_assigned_id
                                      }}<span class="nm">{{
                                        item.location_description
                                        }}</span></span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Map" [disabled]="mapTab">
                <div class="tabContSec" *ngIf="selectedIndex !== 0">
                  <div class="mapSec">
                    <div class="ProfTop">
                      <img *ngIf="
                          assetProfileList.atm_information[0].atm_main_image;
                          else mapdefaultImage
                        " src="{{
                          assetProfileList.atm_information[0].atm_main_image
                        }}" alt="" height="50" width="50" />
                      <ng-template #mapdefaultImage>
                        <img src="./assets/images/img1.jpg" alt="" />
                      </ng-template>
                      <div class="thmbDtl">
                        {{
                        assetProfileList.atm_information[0]
                        .institution_assigned_id
                        }}<br />
                        {{ assetProfileList.atm_information[0].atm_description
                        }}<br />
                        {{
                        assetProfileList.atm_information[0]
                        .location_description
                        }}<br />
                      </div>
                    </div>
                    <agm-map [zoom]="markers.zoom" [latitude]="markers.lat" [longitude]="markers.lng">
                      <agm-marker [latitude]="markers.lat" [longitude]="markers.lng"
                        [markerDraggable]="markers.draggable">
                        <agm-info-window [disableAutoPan]="markers.disableAutoPan" [isOpen]="true">
                          <div>
                            {{
                            assetProfileList.atm_information[0]
                            .institution_assigned_id
                            }}<br />
                            {{
                            assetProfileList.atm_information[0]
                            .atm_description
                            }}<br />
                            {{
                            assetProfileList.atm_information[0]
                            .location_description
                            }}<br />
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Action Items" [disabled]="actionItemsTab">
                <div class="tabContSec" *ngIf="selectedIndex !== 0">
                  <div class="action_item">
                    <div class="ProfTop">
                      <img *ngIf="
                          assetProfileList.atm_information[0].atm_main_image;
                          else mapdefaultImage
                        " src="{{
                          assetProfileList.atm_information[0].atm_main_image
                        }}" alt="" height="50" width="50" />
                      <ng-template #mapdefaultImage>
                        <img src="./assets/images/img1.jpg" alt="" />
                      </ng-template>
                      <div class="thmbDtl">
                        {{
                        assetProfileList.atm_information[0]
                        .institution_assigned_id
                        }}<br />
                        {{ assetProfileList.atm_information[0].atm_description
                        }}<br />
                        {{
                        assetProfileList.atm_information[0]
                        .location_description
                        }}<br />
                      </div>
                    </div>
                    <div class="tableDv">
                      <table border="0" cellspacing="0" width="100%" cellpadding="0">
                        <tr>
                          <th>Image</th>
                          <th>
                            Action Item Category
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </th>
                          <th>
                            Action Item Subcategory
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </th>
                          <th>
                            Action Item Description
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </th>
                          <th>Action</th>
                        </tr>

                        <ng-container *ngIf="
                            actionItemsList.length > 0;
                            then showActionItem;
                            else dontShowActionItem
                          "></ng-container>
                        <ng-template #showActionItem>
                          <tr *ngFor="let item of actionItemsList; let i = index" id="remove_action_item_{{ i }}"
                            class="action_items">
                            <td>
                              <img src="{{item.ImageURL}}" />

                            </td>
                            <td>{{ actionItemsList[i].IssueType }}</td>
                            <td>{{ actionItemsList[i].IssueSubType }}</td>
                            <td>{{ actionItemsList[i].IssueDescription }}</td>
                            <td>
                              <form>
                                <div class="SlctRow">
                                  <select class="slctDd" id="action_cls_{{ i }}" (change)="
                                      opeModal(
                                        $event.target.value,
                                        actionItemsList[i],
                                        i
                                      )
                                    ">
                                    <option value="">Action</option>
                                    <option value="email">Email Item</option>
                                    <option value="ignr_item">
                                      Ignore Item
                                    </option>
                                  </select>
                                </div>
                              </form>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template #dontShowActionItem>
                          <tr>
                            <td colspan="5" >
                              <table border="0" cellspacing="0" width="100%" cellpadding="0">
                                <tr>
                                  <td style=" text-align: center;"> No Action Items Pending</td>
                                </tr>

                                <tr></tr>
                              </table>
                            </td>
                          </tr>
                        </ng-template>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Images" [disabled]="imageTab">
                <div class="tabContSec" *ngIf="selectedIndex !== 0">
                  <div class="imagesSec">
                    <div class="ProfTop">
                      <img *ngIf="
                          assetProfileList.atm_information[0].atm_main_image;
                          else mapdefaultImage
                        " src="{{
                          assetProfileList.atm_information[0].atm_main_image
                        }}" alt="" height="50" width="50" />
                      <ng-template #mapdefaultImage>
                        <img src="./assets/images/img1.jpg" alt="" />
                      </ng-template>
                      <div class="thmbDtl">
                        {{
                        assetProfileList.atm_information[0]
                        .institution_assigned_id
                        }}<br />
                        {{ assetProfileList.atm_information[0].atm_description
                        }}<br />
                        {{
                        assetProfileList.atm_information[0]
                        .location_description
                        }}<br />
                      </div>
                    </div>
                    <div class="tableDv infoTblDv">
                      <table border="0" cellspacing="0" width="100%" cellpadding="0">
                        <ng-container *ngIf="
                            assetImageList.length > 0;
                            then showImageItem;
                            else dontshowImageItem
                          "></ng-container>
                        <ng-template #showImageItem>
                          <tr *ngFor="let item of assetImageList; let i = index">
                            <td>
                              <table border="0" cellspacing="0" width="100%" cellpadding="0">
                                <tr>
                                  <td>
                                    <ng-container *ngIf="
                                        item.ImageURL != '';
                                        then showMainImageinAssetlistImage;
                                        else dontshowMainImageinAssetlistImage
                                      ">
                                    </ng-container>
                                    <ng-template #dontshowMainImageinAssetlistImage>
                                      <img src="./assets/images/img1.jpg" alt="" />
                                    </ng-template>
                                    <ng-template #showMainImageinAssetlistImage>
                                      <img class="d-block w-100" src="{{ item.ImageURL }}" />
                                    </ng-template>
                                  </td>
                                  <td>{{ item.date }}</td>
                                  <td>{{ item.ServiceName }}</td>
                                  <td>
                                    <form>
                                      <div class="SlctRow">

                                        <button class="mrInfoBtn" mat-stroked-button color="primary"
                                          (click)="clicktoseeImage(i)">
                                          <span class="mrInfoIcn">
                                            <img src="./assets/images/mrInfBtn.png" alt="" />
                                          </span>
                                          <!-- More Info
                                          <mat-icon>chevron_right</mat-icon> -->
                                        </button>

                                        <!--<select class="slctDd">
                                          <option>View Images</option>
                                        </select>-->
                                      </div>
                                    </form>
                                  </td>
                                </tr>
                              </table>

                              <table border="0" class="images_details" cellspacing="0" width="100%" cellpadding="0"
                                id="images_id_{{ i }}" style="display: none">
                                <tr>
                                  <td>
                                    <div>
                                      <div>
                                        <ng-container *ngIf="
                                            item.image_list.length > 0;
                                            then show_image_list;
                                            else dontShow_image_list
                                          "></ng-container>
                                        <ng-template #show_image_list>
                                          <div class="row">
                                            <div class="column" *ngFor="
                                                let image of item.image_list;
                                                let j = index
                                              ">
                                              <div class="columnImg">
                                                <img src="{{ image.ImageURL }}" alt="{{ j }} slide"
                                                  style="cursor: pointer" (click)="
                                                    viewImageSingle(image)
                                                  " />
                                              </div>
                                              <p>{{ image.ImageName }}</p>
                                            </div>
                                          </div>
                                        </ng-template>
                                        <ng-template #dontShow_image_list>
                                          No data found
                                        </ng-template>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </ng-template>

                        <ng-template #dontshowImageItem>
                          <tr>
                            <td>
                              <table border="0" cellspacing="0" width="100%" cellpadding="0">
                                <tr>
                                  <td>No Data Found</td>
                                </tr>

                                <tr></tr>
                              </table>
                            </td>
                          </tr>
                        </ng-template>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="History" [disabled]="historyTab">
                <div class="tabContSec" *ngIf="selectedIndex !== 0">
                  <div class="history">
                    <div class="ProfTop">
                      <img *ngIf="
                          assetProfileList.atm_information[0].atm_main_image;
                          else mapdefaultImage
                        " src="{{
                          assetProfileList.atm_information[0].atm_main_image
                        }}" alt="" height="50" width="50" />
                      <ng-template #mapdefaultImage>
                        <img src="./assets/images/img1.jpg" alt="" />
                      </ng-template>
                      <div class="thmbDtl">
                        {{
                        assetProfileList.atm_information[0]
                        .institution_assigned_id
                        }}<br />
                        {{ assetProfileList.atm_information[0].atm_description
                        }}<br />
                        {{
                        assetProfileList.atm_information[0]
                        .location_description
                        }}<br />
                      </div>
                    </div>
                    <div class="tableDv infoTblDv">
                      <table border="0" cellspacing="0" width="100%" cellpadding="0">
                        <ng-container *ngIf="
                            assetHistoryList.length > 0;
                            then showHistoryItem;
                            else dontshowHistoryItem
                          "></ng-container>

                        <ng-template #showHistoryItem>
                          <tr *ngFor="let item of assetHistoryList; let i = index">
                            <td>
                              <table border="0" cellspacing="0" width="100%" cellpadding="0">
                                <tr>
                                  <td>
                                    <img *ngIf="
                                        item?.ImageURL;
                                        else mapdefaultImage
                                      " src="{{ item.ImageURL }}" alt="" height="50" width="50" />
                                    <ng-template #mapdefaultImage>
                                      <img src="./assets/images/img1.jpg" alt="" />
                                    </ng-template>
                                  </td>
                                  <td>{{ item.date }}</td>
                                  <td>{{ item.ServiceName }}</td>
                                  <td class="isu">
                                    <a href="javascript:void(0)" (click)="viewIsIssue(item.JobId, i)">Is issue</a>
                                  </td>
                                  <td class="pdfs">
                                    <ul>
                                      <li class="pdfLnks" *ngFor="
                                          let reportItem of item.reports;
                                          let i = index
                                        ">
                                        <a target="_blank" href="{{reportItem.full_report_url}}" download><img
                                            src="./assets/images/pd.png" /></a><span>{{reportItem.ReportName}}</span>
                                      </li>
                                    </ul>
                                  </td>
                                  <td>
                                    <form>
                                      <div class="SlctRow">

                                        <button class="mrInfoBtn" mat-stroked-button color="primary" (click)="
                                            clicktoseehistory(i, item.JobId)
                                          ">
                                          <span class="mrInfoIcn">
                                            <img src="./assets/images/mrInfBtn.png" alt="" />
                                          </span>
                                          <!-- More Info
                                          <mat-icon>chevron_right</mat-icon> -->
                                        </button>
                                        <!--<select class="slctDd">
                                          <option>More Info</option>
                                        </select>-->
                                      </div>
                                    </form>
                                  </td>
                                </tr>
                              </table>
                              <table class="myhistory" border="0" cellspacing="0" width="100%" cellpadding="0"
                                id="history_details_{{ i }}" style="display: none">
                                <tr>
                                  <td>
                                    <div>
                                      <table border="0" cellspacing="0" width="100%" cellpadding="0" class="mrInfoTbl">
                                        <tr>
                                          <th>Question</th>
                                          <th>Answer</th>
                                          <th>Images</th>
                                        </tr>
                                        <ng-container *ngIf="
                                            item.question_list.length > 0;
                                            then show_question_list;
                                            else dontShow_question_list
                                          "></ng-container>
                                        <ng-template #show_question_list>
                                          <tr *ngFor="
                                              let question of item.question_list;
                                              let i = index
                                            ">
                                            <td>{{ question.Question }}</td>
                                            <td>{{ question.Answer }}</td>
                                            <td class="td-image">
                                              <img (click)="
                                                  historyImageView(question)
                                                " style="cursor: pointer" src="{{ question.ImageURL }}" alt="" />
                                            </td>
                                          </tr>
                                        </ng-template>
                                        <ng-template #dontShow_question_list>
                                          <tr>
                                            <td>No Data found</td>
                                          </tr>
                                        </ng-template>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template #dontshowHistoryItem>
                          <tr>
                            <td>
                              <table border="0" cellspacing="0" width="100%" cellpadding="0">
                                <tr>
                                  <td>No Data Found</td>
                                </tr>

                                <tr></tr>
                              </table>
                            </td>
                          </tr>
                        </ng-template>
                      </table>
                      <div *ngFor="let item of assetHistoryList; let i = index" id="isIssueDiv_{{ item.JobId }}"
                        style="display: none">
                        <div class="isuSec" *ngFor="
                            let ignoredIssue of item.ignored_issue;
                            let i = index
                          ">
                          <table border="0" cellspacing="0" width="100%" cellpadding="0">
                            <tr>
                              <td>
                                <ng-container *ngIf="ignoredIssue.ImageId?.length > 0">
                                  <img src="{{ baseUrl }}images/{{
                                      ignoredIssue.JobId
                                    }}/{{ ignoredIssue.ATMID }}/{{
                                      ignoredIssue.ImageId
                                    }}.{{ ignoredIssue.Extension }}" alt="image" />
                                </ng-container>
                              </td>
                              <td width="100">&nbsp;</td>
                              <td>
                                <span>Action Item Category</span>
                                {{ ignoredIssue.IssueType }}
                              </td>
                              <td>
                                <span>Action Item Subcategory</span>
                                {{ ignoredIssue.IssueSubType }}
                              </td>
                              <td>
                                <span>Action Item Description</span>
                                {{ ignoredIssue.IssueDescription }}
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </table>
                          <div class="isuCont">
                            <p>
                              Action item ignored by:
                              <!--<a href="mailto:jchelsey@gmail.com"
                              >jchelsey@gmail.com
                            </a>-->
                              <b>{{ ignoredIssue.CreatedBy }}</b>
                              on {{ fixDateFormat(ignoredIssue.IgnoreDate) }}
                            </p>
                            <p>Comment: {{ ignoredIssue.ignoreComment }}</p>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <!-- tab end  -->
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</app-layout>