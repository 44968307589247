import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AssetsService } from '../../services/assets.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { IgnoreitemComponent } from 'src/app/pages/ignoreitem/ignoreitem.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SendassettoemailComponent } from 'src/app/pages/sendassettoemail/sendassettoemail.component';
import { environment } from 'src/environments/environment';
import { BsDatepickerDirective, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
//import { DatePipe } from '@angular/common';
/*declare var require: any;
const FileSaver = require('file-saver');*/


import { saveAs } from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';




export interface ImageViewPopUp {
  imageURL: string;
}

@Component({
  selector: 'app-assetlink',
  templateUrl: './assetlink.component.html',
  styleUrls: ['./assetlink.component.css'],
})
export class AssetlinkComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(BsDaterangepickerDirective, { static: false }) datepicker?: BsDaterangepickerDirective;
  @HostListener('window:scroll')
  onScrollEvent() {
    this.datepicker?.hide();
  }
  public imageFullURL: string = '';
  public isLoading: boolean = true;
  public assetList: any = [];
  public selectedIndex: number = 0;
  public assetListTab: boolean = false;
  public assetProfileTab: boolean = true;
  public mapTab: boolean = true;
  public actionItemsTab: boolean = true;
  public imageTab: boolean = true;
  public historyTab: boolean = true;
  public assetFilterPanelFlag: boolean = true;
  public assetFilterList: any = [];
  public serviceNameList: any = [];
  public questionList: any = [];
  public answerList: any = [];
  public stateList: any = [];
  public cityList: any = [];
  public manufacturerList: any = [];
  public modelList: any = [];
  public imageList: any = [];

  public assetFilterForm: FormGroup;
  public assetProfileList: any = [];
  public actionItemsList: any = [];
  public assetImageList: any = [];
  public assetHistoryList: any = [];
  public lat = 26.7271;
  public lng = 88.3953;
  public baseUrl: string = environment.S3Url;
  public customer_id = '';
  public asset_count = 0;
  public subcategory = '';
  public category = '';
  public atm_id = '';
  public isIssueIsShown: boolean = false;


  public today = new Date();
  public scrolClick = 0;

  bsRangeValue: Date[];
  public remove_act = 0;
  modalRef: BsModalRef;
  public date: any;
  dataSource: any;
  rowPageIndex = 0;
  totalrecordFound = 0;
  isFilterApplied: boolean = false;
  paginateOnce = 0;
  displayedColumns: string[] = [
    'InstitutionAssignedID',
    'action_count',
    'ServiceName',
  ];

  getAssetListingParams = {
    "RowIndex": "",
    "RowItem": "",
  };

  // getJobIdsParams = {
  //   "company_id": '',
  //   "job_status_id": '',
  //   "year": '',
  //   'month': '',
  //   'job_id': '',
  //   'customerid': '',
  //   'job_number': '',
  //   'role': '',
  //   'user_id': '',
  //   "RowItem":'',
  //   "RowIndex":''
  // };



  constructor(
    private _assetService$: AssetsService,
    private _snack$: SnackbarService,
    private _formBuilder: FormBuilder,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public http: HttpClient

  ) { }
  ngOnInit(): void {
    this.isLoading = true;
    //generating the form

    this.customer_id = localStorage.getItem('customer_id');
    this.route.queryParams.subscribe((params) => {
      if (params['sub_catecories']) {
        this.subcategory = params['sub_catecories'];
      }
      if (params['category']) {
        this.category = params['category'];
      }
      if (params['atm_id']) {
        this.atm_id = params['atm_id'];
      }
    });
    //alert(this.subcategory);

    this.generateForm();
    //calling the assets list
    this.listAllAssets();

    //calling the asset filters
    this.assetFilters();
    this.hide();
  }

  markers: marker = {
    lat: 26.7271,
    lng: 88.3953,
    label: '',
    zoom: 11,
    draggable: true,
    disableAutoPan: false,
  };

  public generateForm = (): void => {
    this.assetFilterForm = this._formBuilder.group({
      installType: [''],
      imageName: [''],
      questions: [''],
      answer: [''],
      states: [''],
      city: [''],
      manufacturer: [''],
      model: [''],
      bsRangeValue: [this.bsRangeValue],
      survey_name: [''],
      action_only: [''],
    });
  };

  hideIsIssue(JobId) {
    document.getElementById('isIssueDiv_' + JobId).style.display = "none";
  }

  viewIsIssue(JobId, index) {

    var tableDisplayValue = document.getElementById('isIssueDiv_' + JobId).style.display;
    if (tableDisplayValue == 'none') {
      document.getElementById('isIssueDiv_' + JobId).style.display = "block";
    }
    else {
      document.getElementById('isIssueDiv_' + JobId).style.display = "none";
    }

    this.clicktoseehistoryHide(index)

  }

  fixDateFormat(stringDate) {
    if (stringDate != null) {
      var newstringDate = stringDate.toString().split('"').join('');
      var rowstringDate = new Date(newstringDate);
      return moment(rowstringDate).format('DD-MM-YYYY hh:mm A');
    }

  }

  downloadPdf(reportItem) {
    const pdfUrl = reportItem.full_report_url;
    const pdfName = reportItem.ReportName;
    this.checkForURL(pdfUrl).subscribe(res => {
      if (res) {

       // alert(res);
        saveAs(pdfUrl, pdfName);
      }
    });
  }

  checkForURL(pdfUrl) {
    return this.http
      .get(pdfUrl, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return pdfUrl;
        }),
        catchError(error => {
          if (error.status === 403) {
            this.router.navigate(['file-not-found']).then();
          }
          return of();
        })
      );
  }

  // checkStatus() {
  //   alert('working');
  // }

  /*
   * listing assets functionality
   */
  public listAllAssets = (rowIndex?: string, rowItem?: string): void => {

    const body: any = {
      customer_id: this.customer_id,
      atm_model_id: '',
      service_id: '',
      atm_manu_id: '',
      state_code: '',
      city: '',
      start_date: '',
      end_date: '',
      question_id: '',
      answer_id: '',
      image_id: '',
      action_item_only: '',
      sort_by_asset_id: '',
      sort_by_action_items: 'desc',
      install_type: '',
      sub_categories: this.subcategory,
      category: this.category,
      atm_id: this.atm_id,
      RowIndex: this.rowPageIndex.toString(),
      RowItem: rowItem ? rowItem.toString() : "10",      
      institute_id: localStorage.getItem('institute_id')
    };
    this._assetService$.listAllAssetsAPI(body).subscribe({
      next: (response: any) => {

        this.totalrecordFound = response.result.len;
        //alert(response.data.tableRows.length);
        this.dataSource = new MatTableDataSource<any>(response.result.atm_list);
        this.dataSource.sort = this.sort;
         
        
        this.assetList = response.result.atm_list;

        this.asset_count = response.result.len;
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  };



  onPaginateChange(event) {
    this.isLoading = true
    this.rowPageIndex = event.pageIndex * event.pageSize

    /**
     * check if filter is applied
     * thaen call the clickToFilter()
     * else call listAllAssets()
     */
    if (this.isFilterApplied) {

      this.clickToFilter(event.pageIndex, event.pageSize, false);
    } else {
      this.listAllAssets(event.pageIndex, event.pageSize);
    }


  }




  /**
   * 
   * changes tab view
   */

  tabClick(event: any) {
    switch (event.index) {
      case 0:
        this.assetFilterPanelFlag = true;
        this.assetProfileTab = true;
        this.mapTab = true;
        this.actionItemsTab = true;
        this.imageTab = true;
        this.historyTab = true;
        this.assetListTab = true;
        break;
      case 1:
        this.assetFilterPanelFlag = false;
        break;
      case 2:
        this.assetFilterPanelFlag = false;
        break;
      case 3:
        this.assetFilterPanelFlag = false;
        break;
      case 4:
        this.assetFilterPanelFlag = false;
        break;
      case 5:
        this.assetFilterPanelFlag = false;
        break;
    }
  }

  /**
   * Asset Details
   */
  public assetDetails = (asset): void => {
    // console.log(asset);
    this.isLoading = true;
    forkJoin([
      this._assetService$.assetProfileAPI({
        ATMID: JSON.stringify(asset.atmid),
      }),
      this._assetService$.assetListActionItemsAPI({
        atm_id: JSON.stringify(asset.atmid),
      }),
      this._assetService$.assetListImagesyAPI({
        atm_id: JSON.stringify(asset.atmid),
      }),
      this._assetService$.assetListHistoryAPI({
        atm_id: JSON.stringify(asset.atmid),
      }),
    ]).subscribe({
      next: (response: any) => {
        if (response[0].code === 200) {
          this.assetProfileList = response[0].result;
          const totalImageCount = (this.assetProfileList && this.assetProfileList.image_list) ? this.assetProfileList.image_list.length : 0;
          if (totalImageCount) {
            const perChunk = 6 // items per chunk    
            const inputArray = this.assetProfileList.image_list
            this.assetProfileList.image_thumb_list = inputArray.reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / perChunk)
              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
              }
              resultArray[chunkIndex].push({
                id: index,
                item: item
              })
              return resultArray
            }, []);
          }
          let mapData = this.assetProfileList;
          if (mapData.atm_information) {
            this.markers.lat = parseFloat(mapData.atm_information[0].lattitude);
            this.markers.lng = parseFloat(mapData.atm_information[0].longitude);
            this.markers.label = `${mapData.atm_information[0].atm_id}`;
            this.markers.draggable = false;
          }
        }
        if (response[1].code === '200') {
          this.actionItemsList = response[1].result.action_list;
        }
        if (response[2].Code === 200) {
          this.assetImageList = response[2].result.image_list;
        }
        if (response[3].Code === 200) {
          this.assetHistoryList = response[3].result.q_list;

          //console.log(this.assetHistoryList[1].question_list[1].JobId , "DATACHECK");
        }
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        //calling the asset filters
        this.assetFilters();
        this.isLoading = false;
        this.selectedIndex = 1;
        this.assetProfileTab = false;
        this.mapTab = false;
        this.actionItemsTab = false;
        this.imageTab = false;
        this.historyTab = false;
        this.assetListTab = false;
        this.assetFilterPanelFlag = false;
      },
    });
  };

  /*
   * Asset filters functionality
   */
  public assetFilters = (): void => {
    const body: any = {
      customer_id: this.customer_id,
      service_id: '',
      question_id: '',
      state_code: '',
      manufacturer_id: '',
      institute_id : localStorage.getItem('institute_id')
    };
    this._assetService$.assetFiltersAPI(body).subscribe({
      next: (response: any) => {
        if (response.code === 200) {
          this.assetFilterList = response.result;
          this.serviceNameList = response.result.service_name;
          //this.questionList=response.result.Question;
          this.imageList = response.result.image_list
          this.stateList = response.result.states;
          this.manufacturerList = response.result.manufacturer_list;
        }
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => { },
    });
  };

  /**
   *Asset list Filter functionality
   */

  clearFilter(rowIndex?: string, rowItem?: string, restPagination: boolean = true) {
    this.assetFilterForm.reset();
    this.isFilterApplied = false;
    this.isLoading = true;
    if (restPagination) {
      this.rowPageIndex = 0;
    }
    const start_date = '';
    const end_date = '';
    const body: any = {
      customer_id: this.customer_id,
      atm_model_id: '',
      service_id: '',
      atm_manu_id: '',
      state_code: '',
      city: '',
      start_date: '',
      end_date: '',
      question_id: '',
      answer_id: '',
      image_id: '',
      action_item_only: '',
      sort_by_asset_id: '',
      sort_by_action_items: 'desc',
      install_type: '',
      sub_categories: '',
      category: '',
      atm_id: '',
      RowIndex: 0, //if cfilter is cleared rest the rowindex to 0
      RowItem: rowItem ? rowItem.toString() : "10",      
      institute_id: localStorage.getItem('institute_id')
    };
    this._assetService$.listAllAssetsAPI(body).subscribe({
      next: (response: any) => {

     

        this.totalrecordFound = response.result.len;
        this.dataSource = new MatTableDataSource<any>(response.result.atm_list);
        this.dataSource.sort = this.sort;
        
        this.asset_count = response.result.len;
        this.isLoading = false;
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => { },
    });

  }




  public clickToFilter(rowIndex?: string, rowItem?: string, restPagination: boolean = true) {
    this.isFilterApplied = true;
    this.isLoading = true;
    if (restPagination) {
      this.rowPageIndex = 0;
    }


    const start_date: Date = this.assetFilterForm.controls.bsRangeValue.value
      ? this.assetFilterForm.controls.bsRangeValue.value[0]
      : '';
    const end_date: Date = this.assetFilterForm.controls.bsRangeValue.value
      ? this.assetFilterForm.controls.bsRangeValue.value[1]
      : '';
    const body: any = {
      customer_id: this.customer_id,
      atm_model_id: this.assetFilterForm.controls.model.value
        ? this.assetFilterForm.controls.model.value
        : '',
      service_id: this.assetFilterForm.controls.survey_name.value
        ? this.assetFilterForm.controls.survey_name.value
        : '',
      atm_manu_id: this.assetFilterForm.controls.manufacturer.value
        ? this.assetFilterForm.controls.manufacturer.value
        : '',
      state_code: this.assetFilterForm.controls.states.value
        ? this.assetFilterForm.controls.states.value
        : '',
      city: this.assetFilterForm.controls.city.value
        ? this.assetFilterForm.controls.city.value
        : '',
      start_date: start_date ? formatDate(start_date, "yyyy-MM-dd", "en-US") : '',
      end_date: end_date ? formatDate(end_date, "yyyy-MM-dd", "en-US") : '',
      question_id: this.assetFilterForm.controls.questions.value
        ? this.assetFilterForm.controls.questions.value
        : '',
      answer_id: this.assetFilterForm.controls.answer.value
        ? this.assetFilterForm.controls.answer.value
        : '',
      image_id: this.assetFilterForm.controls.imageName.value
        ? this.assetFilterForm.controls.imageName.value
        : '',
      action_item_only: this.assetFilterForm.controls.action_only.value
        ? 1
        : '',
      sort_by_asset_id: '',
      sort_by_action_items: 'desc',
      install_type: this.assetFilterForm.controls.installType.value
        ? this.assetFilterForm.controls.installType.value
        : '',
      sub_categories: this.subcategory ? this.subcategory : '',
      category: this.category ? this.category : '',
      atm_id: this.atm_id ? this.atm_id : '',
      RowIndex: this.rowPageIndex.toString(),
      RowItem: rowItem ? rowItem.toString() : "10",
      institute_id: localStorage.getItem('institute_id')
    };
    this._assetService$.listAllAssetsAPI(body).subscribe({
      next: (response: any) => {

         if (restPagination) {
           this.paginator.pageIndex = 0;
           this.getAssetListingParams.RowIndex = "0";
           this.getAssetListingParams.RowIndex = "10";

         }

        //alert(response.data.tableRows.length);
        this.totalrecordFound = response.result.len;
        this.dataSource = new MatTableDataSource<any>(response.result.atm_list);
        this.dataSource.sort = this.sort;
         
        
        // this.assetList = response.result.atm_list;
        this.asset_count = response.result.len;
        this.isLoading = false;
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => { },
    });
  }

  /**
   * Filter Dropdown
   */

  public filterList(change_from = null) {
    //alert(this.assetFilterForm.controls.survey_name.value)
    const body: any = {
      customer_id: this.customer_id,
      service_id: this.assetFilterForm.controls.survey_name.value
        ? this.assetFilterForm.controls.survey_name.value
        : '',
      question_id: this.assetFilterForm.controls.questions.value
        ? this.assetFilterForm.controls.questions.value
        : '',
      state_code: this.assetFilterForm.controls.states.value
        ? this.assetFilterForm.controls.states.value
        : '',
      manufacturer_id: this.assetFilterForm.controls.manufacturer.value
        ? this.assetFilterForm.controls.manufacturer.value
        : '',
        
      institute_id : localStorage.getItem('institute_id')
    };
    this._assetService$.assetFiltersAPI(body).subscribe({
      next: (response: any) => {
        if (response.code === 200) {
          this.assetFilterList = response.result;

          if (change_from == 'service') {
            this.questionList = response.result.Question;
          }
          if (change_from == 'question') {
            this.answerList = response.result.answer_list;
          }
          //stateList
          if (change_from == 'state') {
            this.cityList = response.result.city_list;
          }
          if (change_from == 'manufacturer') {
            this.modelList = response.result.model_list;
          }
        }
      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => { },
    });
  }

  //hide on init
  hide = () => {
    //alert("jgjg");

    //Hide all Elements
    const elements = Array.from(
      document.getElementsByClassName(
        'myhistory'
      ) as HTMLCollectionOf<HTMLElement>
    );
    //console.log(elements[0].style)
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
  };


  /**
   * Fuction shows the history details 
   */

  clicktoseehistoryHide = (index) => {
    //alert('working');
    let element = document.getElementById('history_details_' + index);
    //Hide all Elements
    const elements = Array.from(
      document.getElementsByClassName(
        'myhistory'
      ) as HTMLCollectionOf<HTMLElement>
    );
    for (var i = 0; i < elements.length; i++) {
      if (i != index) {
        elements[i].style.display = 'none';
      }
    }
    //alert(element.style.display )
    if (element.style.display == 'none') {
      //element.style.display = 'table';
    } else {
      element.style.display = 'none';
    }

    //hideIsIssue()
  };


  clicktoseehistory = (index, JobId) => {
    console.log('clicktoseehistory', index);
    this.hideIsIssue(JobId);
    //alert('working');
    let element = document.getElementById('history_details_' + index);
    //Hide all Elements
    const elements = Array.from(
      document.getElementsByClassName(
        'myhistory'
      ) as HTMLCollectionOf<HTMLElement>
    );
    for (var i = 0; i < elements.length; i++) {
      if (i != index) {
        elements[i].style.display = 'none';
      }
    }
    //alert(element.style.display )
    if (element.style.display == 'none') {
      element.style.display = 'table';
    } else {
      element.style.display = 'none';
    }

    //hideIsIssue()
  };


  /**
   *Open Modals for ignore action item issue or email issue 
   */
  opeModal(value, full_object = null, index = null) {
    if (value == 'ignr_item') {
      full_object.index = index;
      this.openActionItemIgnoreModal(full_object);
    }
    if (value == 'email') {
      full_object.index = index;
      full_object.asset_profile = this.assetProfileList;
      this.openSendAssetToemail(full_object);
    }
  }

  /**
   *Open Modals for ignore action item issue 
   */

  openActionItemIgnoreModal(full_object) {
    full_object.username = localStorage.getItem('username');
    let ignoreItemModal = this.modalRef;

    ignoreItemModal = this.modalService.show(IgnoreitemComponent, {
      initialState: {
        title: 'Ignore Item',
        data: full_object,
      },
    });
    // ignoreItemModal.onHide.toPromise().then(()=>{
    //   alert(full_object.index);

    // });

    ignoreItemModal.onHide.subscribe(() => {
      //alert(full_object.index);
      let elem = document.getElementsByClassName('action_items');
      //alert(elem.length );
      ///alert (this.actionItemsList.length);


      if (elem.length < this.actionItemsList.length) {
        this.actionItemsList.splice(full_object.index, 1)

      }


    })
  }

  /**
  *remove act items
  */
  public set_remove_act_items(value) {
    this.remove_act = value;
  }

  /**
   *Send asset to email
   */
  openSendAssetToemail(full_object) {
    full_object.username = localStorage.getItem('username');

    this.modalRef = this.modalService.show(SendassettoemailComponent, {
      initialState: {
        title: 'Send Asset To Email',
        data: full_object,
      },
    });
  }

  /**
   * Show image
   * 
   */
  public clicktoseeImage(index) {
    console.log('clicktoseeImage', index);
    // alert(index);

    let element = document.getElementById('images_id_' + index);
    //Hide all Elements
    const elements = Array.from(
      document.getElementsByClassName(
        'images_details'
      ) as HTMLCollectionOf<HTMLElement>
    );
    for (var i = 0; i < elements.length; i++) {
      if (i != index) {
        elements[i].style.display = 'none';
      }
    }
    //alert(element.style.display )
    if (element.style.display == 'none') {
      element.style.display = 'table';
    } else {
      element.style.display = 'none';
    }
  }

  /**
   * 
   * Navigate to asset list 
   */

  public navToProd(obj) {
    let item = obj;
    let redirectTo = "assets";
    this.router.navigate([redirectTo], { queryParams: { atm_id: item.atm_id } }).then(
      () => {
        window.location.reload();
      }

    );
  }

  public showSliderThumb(id) {

    //alert("carousel-selector-"+id);
    let rem = document.getElementsByClassName('remove-selection-thumb-slider');
    for (let i = 0; i < rem.length; i++) {
      rem[i].classList.remove("thmb")
    }

    console.log(rem);
    let element = document.getElementById("carousel-selector-" + id);
    element.classList.add("thmb");


  }



  public stopscrol() {
    if (this.scrolClick == 0) {
      document.body.classList.add("stop-scrolling");
      this.scrolClick = 1;
    } else {
      document.body.classList.remove("stop-scrolling");
      this.scrolClick = 0;
    }

    //alert(this.scrolClick);

  }

  viewImageSingle(image) {

    const dialogRef = this.dialog.open(DialogSingleImage, {
      width: '90%',
      height: '90%',
      data: { imageURL: image.ImageURL },
    });
  }

  historyImageView(question) {
    const dialogRef = this.dialog.open(DialogSingleImage, {
      width: '90%',
      height: '90%',
      data: { imageURL: question.ImageURL },
    });
  }

  exportToExcel() {
    this.isLoading = true;

    const start_date: Date = this.assetFilterForm.controls.bsRangeValue.value?this.assetFilterForm.controls.bsRangeValue.value[0]:'';
    const end_date: Date = this.assetFilterForm.controls.bsRangeValue.value?this.assetFilterForm.controls.bsRangeValue.value[1]: '';

    const body: any = {
      customer_id: this.customer_id,
      atm_model_id: this.assetFilterForm.controls.model.value ? this.assetFilterForm.controls.model.value : '',
      service_id: this.assetFilterForm.controls.survey_name.value ? this.assetFilterForm.controls.survey_name.value : '',
      atm_manu_id: this.assetFilterForm.controls.manufacturer.value ? this.assetFilterForm.controls.manufacturer.value : '',
      state_code: this.assetFilterForm.controls.states.value ? this.assetFilterForm.controls.states.value : '',
      city: this.assetFilterForm.controls.city.value ? this.assetFilterForm.controls.city.value : '',
      start_date: start_date ? formatDate(start_date, "yyyy-MM-dd", "en-US") : '',
      end_date: end_date ? formatDate(end_date, "yyyy-MM-dd", "en-US") : '',
      question_id: this.assetFilterForm.controls.questions.value ? this.assetFilterForm.controls.questions.value : '',
      answer_id: this.assetFilterForm.controls.answer.value ? this.assetFilterForm.controls.answer.value : '',
      image_id: this.assetFilterForm.controls.imageName.value ? this.assetFilterForm.controls.imageName.value : '',
      action_item_only: this.assetFilterForm.controls.action_only.value ? 1 : '',
      sort_by_asset_id: '',
      sort_by_action_items: 'desc',
      install_type: this.assetFilterForm.controls.installType.value ? this.assetFilterForm.controls.installType.value : '',
      sub_categories: this.subcategory ? this.subcategory : '',
      category: this.category ? this.category : '',
      atm_id: this.atm_id ? this.atm_id : '',

    };

    this._assetService$.exportAssetsAPI(body).subscribe({
      next: (response: any) => {
        if (response.result.atm_list.length > 0) {
          let userList = response.result.atm_list.map((asset) => {
            const container = {};
            container["ATM ID"] = asset.InstitutionAssignedID;
            container["Description"] = asset.ATMDescription;
            container["Model"] = asset.model;
            container["Address"] = asset.Address1;
            container["City"] = asset.City;
            container["State"] = asset.StateCode;
            container["Zip"] = asset.zip;
            container["Latitude"] = asset.Latitude;
            container["Longitude"] = asset.Longitude;
            container["Last Service Date"] = asset.FieldComplete;
            container["Last Service"] = asset.ServiceName;
            container["Main Image"] = asset.atm_main_image;

            return container;
          })

          this.exportAsExcelFile(userList, 'assetlist');
        } else {
          this._snack$.notifyError('No data to export', 'OK');
        }



      },
      error: (err: Error) => {
        this._snack$.notifyError(err['error'].message, 'OK');
      },
      complete: () => {
        this.isLoading = false;
      },
    });



    // let userList = this.assetList.map((asset) => {
    //   const container = {};
    //   container["ATM ID"] = asset.InstitutionAssignedID;
    //   container["Description"] = asset.ATMDescription;
    //   container["Model"] = asset.model;
    //   container["Address"] = asset.Address1;
    //   container["City"] = asset.City;
    //   container["State"] = asset.StateCode;
    //   container["Zip"] = asset.zip;
    //   container["Latitude"] = asset.Latitude;
    //   container["Longitude"] = asset.Longitude;
    //   container["Last Service Date"] = asset.FieldComplete;
    //   container["Last Service"] = asset.ServiceName;
    //   container["Main Image"] = asset.atm_main_image;

    //   return container;
    // })

    // this.exportAsExcelFile(userList, 'assetlist');


  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'data': worksheet
      },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs.saveAs(data, fileName + '_export_' + new Date().getFullYear() + '_' + new Date().getHours() + '_' + new Date().getMinutes() + '_' + new Date().getSeconds() + EXCEL_EXTENSION);
  }



}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  zoom?: number;
  draggable?: boolean;
  disableAutoPan?: boolean;
}
import { NgxImageZoomModule } from 'ngx-image-zoom';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'dialog-single-image',
  templateUrl: 'dialog-single-image.html',
  styleUrls: ['assetlink.component.css'],
})
export class DialogSingleImage {
  constructor(
    public dialogRef: MatDialogRef<DialogSingleImage>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewPopUp

  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
